import axios from 'axios'
import { StreamChat } from 'stream-chat'
import store from '../core/store'
import { chatType } from '../constants/chatType'
import { CustomerRoles } from '../constants/customerRoles'
import {
  clearQuotedMessage,
  setPinnedMessage,
  clearPinnedMessage,
  disableChatInput,
  setAutoJoinChat,
  setChatCooldown,
} from '../reducers/uiSlice'
import { setChannelId, addNewMessageCount } from '../reducers/callSlice'
import { ChatErrors, MessageTypes } from '../constants/chatErrors'
import { sendEventToElastic } from '@gojiraf/analytics'
import ReactGA from 'react-ga4'
const { REACT_APP_STREAM_ACCESS_KEY, REACT_APP_CHAT_SERVICE } = process.env

class ChatService {
  constructor() {
    this.client = new StreamChat(REACT_APP_STREAM_ACCESS_KEY, { timeout: 5000 })
    this.connectionTimeout = null
  }

  get dispatch() {
    return store.dispatch
  }

  async getStreamToken({ userId, tokens }) {
    const {
      data: { streamToken },
    } = await axios.post(
      `${REACT_APP_CHAT_SERVICE}/streamChat/getToken`,
      {
        userId,
      },
      {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
        timeout: 5000,
      },
    )
    return streamToken
  }

  async sendErrorMetricsJoinChat({ user, errorType, errorCode }) {
    ReactGA.event({
      category: 'Chat-ChatService',
      action: `${errorType}-error-connecting-user-to-chat-with-code-${errorCode}`,
      label: `${errorType}-error-connecting-user-to-chat-with-code-${errorCode}`,
    })
    const storeState = store.getState()
    await sendEventToElastic(
      storeState.store.current,
      user,
      'errors',
      `${errorType}-error-connecting-user-to-chat-with-code-${errorCode}`,
    )
  }

  sendMessageErrorHandler(error) {
    switch (error.code) {
      case ChatErrors.MutedByAdmin:
        store.dispatch(disableChatInput())
        break
      case ChatErrors.BadWord:
        console.error(error)
        break
      default:
        console.error(error)
        break
    }
  }

  async queryMember({ userId, channelId }) {
    const channel = this.client.channel(chatType.LIVESTREAM, channelId)
    try {
      const { members } = await channel.queryMembers({ user_id: userId })
      return members[0]
    } catch (error) {
      console.error(error)
      return error
    }
  }

  async muteUser({ userId, moderatorId, channelId }) {
    const channel = this.client.channel(chatType.LIVESTREAM, channelId)
    const bannedUser = await channel.banUser(userId, {
      banned_by_id: moderatorId,
      reason: 'Muted by moderator',
    })

    return bannedUser
  }

  async sendMessage({ channel, message, role }) {
    const sentMessage = channel.sendMessage({
      text: message,
    })
    if (
      channel.data.cooldown != null &&
      channel.data.cooldown > 0 &&
      role === CustomerRoles.BUYER
    ) {
      store.dispatch(setChatCooldown(true))
      setTimeout(() => {
        store.dispatch(setChatCooldown(false))
      }, channel.data.cooldown * 1000)
    }
    await sentMessage
    if (sentMessage?.message?.type === MessageTypes.error) throw { code: ChatErrors.BadWord }
  }

  async sendReplyMessage({ quotedMessage, channel, message }) {
    store.dispatch(clearQuotedMessage())
    await channel.sendMessage({
      text: message,
      quoted_message_id: quotedMessage.id,
    })
  }

  async sendMessageToChat({ quotedMessage, channelId, message, role }) {
    try {
      const channel = await this.client.channel(chatType.LIVESTREAM, channelId)
      if (quotedMessage) {
        await this.sendReplyMessage({ quotedMessage, channel, message })
      } else {
        await this.sendMessage({ channel, message, role })
      }
    } catch (error) {
      this.sendMessageErrorHandler(error)
      return error
    }
    return 'ok'
  }

  async addMember({ user, channelId }) {
    const channel = await this.client.channel(chatType.LIVESTREAM, channelId)
    await channel.addMembers([user.id])
  }

  async requestPinnedMessage(channelId) {
    try {
      const channel = await this.client.channel(chatType.LIVESTREAM, channelId)
      const { messages } = await channel.getPinnedMessages({ limit: 1 })
      const lastPinnedMessage = messages[messages.length - 1]
      if (lastPinnedMessage) store.dispatch(setPinnedMessage(lastPinnedMessage))
    } catch (error) {
      console.error(error)
    }
  }

  async upsertUser({ user, channelId }) {
    const { id: userId, tokens, name, role } = user
    const upsertRole = await axios.patch(
      `${REACT_APP_CHAT_SERVICE}/streamChat/upsertRoles`,
      {
        eventId: channelId,
        userId,
        name,
        role,
      },
      {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
        timeout: 5000,
      },
    )

    return upsertRole
  }

  async connectUser({ id, name, streamToken }) {
    await this.client.connectUser(
      {
        id,
        name,
      },
      streamToken,
    )
  }

  connectionStateListener() {
    this.client.on('connection.changed', (e) => {
      if (e.online) {
        if (this.connectionTimeout !== null) {
          clearTimeout(this.connectionTimeout)
          this.connectionTimeout = null
        }
      } else {
        this.connectionTimeout = setTimeout(() => {
          store.dispatch(setAutoJoinChat(false))
          this.connectionTimeout = null
        }, 5000)
      }
    })
  }

  messagesNotificationListener(channel) {
    channel.on('message.new', () => {
      store.dispatch(addNewMessageCount())
    })
    channel.on('notification.message_new', () => {
      store.dispatch(addNewMessageCount())
    })
  }
  messageUpdateListener(channel) {
    channel.on('message.updated', (event) => {
      if (event.message.pinned === false) {
        store.dispatch(clearPinnedMessage())
      } else {
        store.dispatch(setPinnedMessage(event.message))
      }
    })
  }

  async initializeChatConnection({ user, channelId, streamToken }) {
    await this.connectUser({ id: user.id, name: user.name, streamToken })
    const channel = this.client.channel(chatType.LIVESTREAM, channelId)
    await channel.watch()
    this.messagesNotificationListener(channel)
    this.messageUpdateListener(channel)
    this.connectionStateListener()
    if (user.role === CustomerRoles.MODERATOR) await this.upsertUser({ user, channelId })
    await this.requestPinnedMessage(channelId)
    store.dispatch(setChannelId(channelId))
    return { client: this.client, channel }
  }

  async joinChat({ user, channelId }) {
    try {
      const streamToken = await this.getStreamToken({ userId: user.id, tokens: user.tokens })
      const { client, channel } = await Promise.race([
        this.initializeChatConnection({ user, channelId, streamToken }),
        new Promise((_, reject) => {
          setTimeout(() => {
            reject(new Error('"timeout of 5000ms exceeded"'))
          }, 5000)
        }),
      ])
      return { client, channel }
    } catch (error) {
      console.error({ error })
      const rateLimitsError = error?.code === ChatErrors.RateLimits
      await this.sendErrorMetricsJoinChat({
        user,
        errorType: rateLimitsError ? 'rate-limits' : 'unexpected',
        errorCode: error.code?.toString() ?? '500',
      })
      store.dispatch(setAutoJoinChat(false))
    }
  }

  async pinMessage(currentlyPinned, message) {
    try {
      if (currentlyPinned) await this.client.unpinMessage(currentlyPinned.id)
      await this.client.pinMessage(message.id)
      store.dispatch(setPinnedMessage(message))
    } catch (error) {
      console.error(error)
    }
  }

  async unpinMessage(currentlyPinned) {
    try {
      await this.client.unpinMessage(currentlyPinned.id)
      store.dispatch(clearPinnedMessage())
    } catch (error) {
      console.error(error)
    }
  }

  listenChannelDeleted(callback) {
    const channelDeletedHandler = (event) => {
      callback(event)
    }
    this.client.on('channel.deleted', channelDeletedHandler)
    return () => {
      this.client.off('channel.deleted', channelDeletedHandler)
    }
  }
}

const instance = new ChatService()

export default instance
