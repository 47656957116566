import React from 'react'
import PropTypes from 'prop-types'
import { Icons } from 'icons'
import { useDispatch, useSelector } from 'react-redux'
import { ChatBubbleOutline } from '@material-ui/icons'
import { ButtonBubble } from '../Call/ButtonBubble'
import { selectMessagesCount, resetMessagesCount } from '../../reducers/callSlice'
import { ChatAccordionArrow, ChatAccordionButton } from './style'

export const ChatAccordion = ({ handleClick, chatOpen }) => {
  const dispatch = useDispatch()
  const messagesCount = useSelector(selectMessagesCount)

  const toggleAccordion = () => {
    handleClick(!chatOpen)
    dispatch(resetMessagesCount())
  }
  return (
    <ChatAccordionButton type="button" onClick={toggleAccordion}>
      {messagesCount && !chatOpen ? (
        <ButtonBubble badgeContent={messagesCount} color="error">
          <ChatBubbleOutline />
        </ButtonBubble>
      ) : (
        <ChatBubbleOutline />
      )}
      <span>Chat</span>
      <ChatAccordionArrow rotate={chatOpen}>
        <Icons.Expand color="white" />
      </ChatAccordionArrow>
    </ChatAccordionButton>
  )
}

ChatAccordion.propTypes = {
  chatOpen: PropTypes.bool,
  handleClick: PropTypes.func,
}
