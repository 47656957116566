import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Splash } from '../components/Splash'
import { getStoreById, selectCurrentStore } from '../reducers/storeSlice'
import StoreUtils from '../utils/storeUtils'
import { PhoneContainer } from '../components/Kit/Container'
import { CallHomeContainer } from './oneToOneLayout'
import LoadingLayout from '../components/LoadingLayout'
import { login } from '@gojiraf/auth'
import { CustomerRoles } from '../constants/customerRoles'
import { useGoogleAnalytics } from '@gojiraf/analytics'
import { useDevices } from '@gojiraf/responsive'

export const CoHostLogin = () => {
  const { isMd } = useDevices()
  const { gaSendPageView } = useGoogleAnalytics()
  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()
  const store = useSelector(selectCurrentStore)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    gaSendPageView()
  }, [])

  useEffect(() => {
    if (!store && params?.storeId) {
      dispatch(getStoreById({ storeId: params.storeId, isMobile: !isMd }))
    } else {
      setIsLoading(false)
    }
  }, [params, store])

  const onSubmit = async ({ pin, name }) => {
    await login({
      userName: name,
      storeId: params.storeId,
      pin,
      role: CustomerRoles.COHOST,
    })
    navigate(`/store/${store.id}${window.location.search}`, { state: { isCohostUser: true } })
  }
  return (
    <>
      {isLoading || store == null ? (
        <Splash />
      ) : (
        <CallHomeContainer
          style={{
            backgroundImage: `url(${StoreUtils.getBackgroundImage({
              isMobile: !isMd,
              backgroundUrlMobile: store.backgroundUrlMobile,
              backgroundUrl: store.backgroundUrl,
            })})`,
          }}
          data-test="background-image"
        >
          <PhoneContainer data-test="login-screen-container">
            <LoadingLayout onSubmit={onSubmit} disabled={false} isCoHostLogin={true} />
          </PhoneContainer>
        </CallHomeContainer>
      )}
    </>
  )
}
