import React from 'react'
import PropTypes from 'prop-types'
import { ActionBar } from './ActionBar'
import { CallAction } from './CallAction'

const CallBottomContainer = ({ onCallFinished, isDesktop }) => {
  return <>{isDesktop ? <ActionBar /> : <CallAction onCallFinished={onCallFinished} />}</>
}

CallBottomContainer.propTypes = {
  onCallFinished: PropTypes.func.isRequired,
  isDesktop: PropTypes.bool.isRequired,
}

export { CallBottomContainer }
