"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  useDevices: () => useDevices
});
module.exports = __toCommonJS(src_exports);

// src/useDevices.ts
var import_react_responsive = require("react-responsive");
var dimensions = {
  xs: "320px",
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  "2xl": "1536px"
};
var xsQuery = `(min-width: ${dimensions.xs})`;
var smQuery = `(min-width: ${dimensions.sm})`;
var mdQuery = `(min-width: ${dimensions.md})`;
var lgQuery = `(min-width: ${dimensions.lg})`;
var xlQuery = `(min-width: ${dimensions.xl})`;
var twoXlQuery = `(min-width: ${dimensions["2xl"]})`;
var devices = /* @__PURE__ */ new Map();
devices.set("xs", xsQuery);
devices.set("sm", smQuery);
devices.set("md", mdQuery);
devices.set("lg", lgQuery);
devices.set("xl", xlQuery);
devices.set("2xl", twoXlQuery);
var useDevices = () => {
  const isXs = (0, import_react_responsive.useMediaQuery)({
    query: devices.get("xs")
  });
  const isSm = (0, import_react_responsive.useMediaQuery)({
    query: devices.get("sm")
  });
  const isMd = (0, import_react_responsive.useMediaQuery)({
    query: devices.get("md")
  });
  const isLg = (0, import_react_responsive.useMediaQuery)({
    query: devices.get("lg")
  });
  const isXl = (0, import_react_responsive.useMediaQuery)({
    query: devices.get("xl")
  });
  const is2xl = (0, import_react_responsive.useMediaQuery)({
    query: devices.get("2xl")
  });
  return {
    isXs,
    isSm,
    isMd,
    isLg,
    isXl,
    is2xl
  };
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  useDevices
});
