export const CallMessage = {
  ADD_CUSTOMER: 'addCustomer',
  CHAT_MESSAGE: 'chatMessage',
  HANG_UP: 'hangUp',
  LIKE_MESSAGE: 'likeMessage',
  ORDER_SHIPPED: 'orderShipped',
  PRODUCT_ADDED: 'productAdded',
  PRODUCT_DELETED: 'productDeleted',
  REMOVE_USER: 'removeUser',
  REQUEST_IDENTITY: 'requestIdentity',
  ON_CUSTOMER_IDENTIFICATION: 'onCustomerIdentification',
  ON_CUSTOMER_IDENTIFICATED: 'onCustomerIdentificated',
  MUTE_AUDIO: 'mute-audio',
  LEAVE_CHANNEL: 'leave-channel',
  PIN_MESSAGE: 'pinMessage',
  REPLY_MESSAGE: 'replyToMessage',
  DELETE_MESSAGE: 'deleteMessage',
  FINISH_COUNTDOWN_MESSAGE: 'finishCountdownMessage',
  START_COUNTDOWN_MESSAGE: 'startCountdownMessage',
  DISPLAY_POPUP_MESSAGE: 'displayPopUpMessage',
  DISPATCH_EXTERNAL_REACTION: 'dispatchExternalReaction',
  DISPATCH_QUICK_ADD_BUTTON: 'dispatchQuickAddButton',
}
