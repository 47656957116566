import React from 'react'
import PropTypes from 'prop-types'
import {
  PopUpContainer,
  PopUpContent,
  PopUpCuponContainer,
  PopUpCuponText,
  PopUpDescription,
  PopUpTitle,
  PopUpTitleContainer,
} from './PopUp.Style'
import 'react-lazy-load-image-component/src/effects/opacity.css'
import { sendEventToElastic, useMatomoAnalytics } from '@gojiraf/analytics'
import { useDevices } from '@gojiraf/responsive'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { IconButton } from '@material-ui/core'
import { CouponIconSVG } from '../../assets/svg/CouponIconSVG'
import MessageWithLink from '../Chat/messages/MessageWithLink'
import { setIsNotificationVisible, setNotificationMessage } from '../../reducers/uiSlice'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

export const PopUp = ({ data }) => {
  const { matomoTrackEvent } = useMatomoAnalytics()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { isMd } = useDevices()
  const { title, promoCode, description } = data

  const showMessage = (message) => {
    dispatch(setNotificationMessage({ notificationMessage: message }))
    dispatch(setIsNotificationVisible({ isNotificationVisible: true }))
  }
  const copyCoupon = () => {
    matomoTrackEvent('InCall > PopUp', `copy-code [${promoCode}]`)
    sendEventToElastic({
      eventType: 'InCall > PopUp',
      event: `copy-code [${promoCode}]`,
    })

    navigator.clipboard.writeText(promoCode)
    showMessage(t('uiElements.copyToClipboard').toUpperCase())
  }

  const showPopUp = !!title || !!promoCode || !!description

  return (
    <>
      {showPopUp ? (
        <PopUpContainer isMobile={!isMd}>
          <PopUpContent>
            {title && (
              <PopUpTitleContainer>
                <CouponIconSVG />
                <PopUpTitle>{title}</PopUpTitle>
              </PopUpTitleContainer>
            )}
            {promoCode && (
              <PopUpCuponContainer>
                <PopUpCuponText>{promoCode}</PopUpCuponText>
                <IconButton color="secondary" aria-label="copy" size="small" onClick={copyCoupon}>
                  <ContentCopyIcon />
                </IconButton>
              </PopUpCuponContainer>
            )}
            {description && (
              <PopUpDescription>
                <MessageWithLink view="PopUp" logType="click-url-pop-up" isDesktop={isMd}>
                  {description}
                </MessageWithLink>
              </PopUpDescription>
            )}
          </PopUpContent>
        </PopUpContainer>
      ) : null}
    </>
  )
}

PopUp.propTypes = {
  data: PropTypes.object.isRequired,
}
