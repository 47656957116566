import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setGoToCheckoutDialog } from '../../reducers/uiSlice'
import { useCheckout } from '../../hooks/useCheckout'
import { openWindow } from '../../utils/browserDetectUtils'
import { selectCurrentStore } from '../../reducers/storeSlice'
import { useViews } from 'state'
import { Dialog, DialogContent, DialogTitle, makeStyles, Typography } from '@material-ui/core'
import { Button } from '../Kit/Buttons'
import styles from '../../styles/dialog.styles'
import { usePIP } from '../../hooks/usePIP'
import { ButtonContainer } from './style'
import { sendEventToElastic, useMatomoAnalytics } from '@gojiraf/analytics'
const useStyles = makeStyles((theme) => styles(theme))

export const GoToCheckoutDialog = ({ paymentGateway, orderData, externalUrl }) => {
  const { t } = useTranslation()
  const { send: sendViews } = useViews()
  const dispatch = useDispatch()
  const store = useSelector(selectCurrentStore)
  const classes = useStyles()
  const { setEnabled } = usePIP()
  const { goToCheckout } = useCheckout(store)
  const { matomoTrackEvent } = useMatomoAnalytics()

  const handleGoBack = () => {
    dispatch(setGoToCheckoutDialog({ paymentGateway: null, orderData: null, showDialog: false }))
    sendViews({ type: 'GO_BACK' })
  }

  const handleOnContinue = () => {
    if (externalUrl !== null) {
      matomoTrackEvent('InCall', 'click-confirm-button-redirect')
      sendEventToElastic({ eventType: 'InCall', event: 'click-confirm-button-redirect' })

      const anchor = document.createElement('a')
      anchor.href = externalUrl
      anchor.target = '_blank'
      anchor.click()
    } else {
      goToCheckout({
        orderData,
        checkoutWindow: openWindow(),
        selectedPaymentGateway: paymentGateway,
        onGoBack: handleGoBack,
      })
    }
    handleGoBack()
  }

  const handleOnCancel = () => {
    setEnabled(false)
    handleGoBack()
  }

  return (
    <Dialog aria-labelledby="simple-dialog-title" maxWidth="xs" open>
      <DialogTitle>
        <Typography
          className={classes.message}
          component="p"
          variant="h6"
          color="primary"
          align="center"
        >
          {t('dialogs.goToCheckout', { storeName: store.name })}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialog__actions}>
        <ButtonContainer>
          <Button variant="contained" onClick={handleOnContinue}>
            {t('dialogs.acceptButton').toUpperCase()}
          </Button>
          <Button variant="text" onClick={handleOnCancel}>
            {t('dialogs.cancelButton').toUpperCase()}
          </Button>
        </ButtonContainer>
      </DialogContent>
    </Dialog>
  )
}

GoToCheckoutDialog.propTypes = {
  paymentGateway: PropTypes.object.isRequired,
  orderData: PropTypes.object.isRequired,
  externalUrl: PropTypes.string.isRequired,
}
